<template>
  <div class="model_bg">
    <div
      class="my_model"
      v-for="(item, index) in modelList"
      :key="index"
      v-if="isShowMsg && item.isShow"
    >
      <audio src="@/assets/images/app/audio.mp3" autoplay></audio>
      <div class="model_title">新的{{ item.type | getTitle }}请求</div>
      <div class="model_picture">
        <img :src="getImg(item.type)" alt="" />
      </div>
      <div class="model_forms">
        <div class="forms">
          <div class="title">报警人名称：</div>
          <div class="content">{{ item.nickname }}</div>
        </div>
        <div class="forms">
          <div class="title">报警人性别：</div>
          <div class="content">{{ item.sex }}</div>
        </div>
        <div class="forms">
          <div class="title">报警人联系电话：</div>
          <div class="content">{{ item.mobile || '未知' }}</div>
        </div>
        <div class="forms">
          <div class="title">案发地址：</div>
          <div class="content">{{ item.address }}</div>
        </div>
      </div>
      <div class="btn_group">
        <div class="btn" @click="itemClick(item)">{{ item.type | getBtn }}</div>
        <div class="cancel_btn" @click="cancelClick(item)">忽略</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modelList: [],
      isShowMsg: true,
    };
  },
  filters: {
    getTitle(type) {
      if (+type === 0) {
        return "视频报警";
      } else if (+type === 1) {
        return "音频报警";
      } else if (+type === 3) {
        return "静默求助";
      } else if (+type === 2) {
        return "留言报警";
      }
    },
    getBtn(type) {
      if (+type === 0 || +type === 1) {
        return "接听";
      } else if (+type === 3 || +type === 2) {
        return "查看";
      }
    },
  },
  watch: {
    $route(v, h) {
      if (v.path === "/login" || h.path === "/login") {
        this.modelList = [];
      }
    },
  },
  methods: {
    getImg(type) {
      if (+type === 0) {
        return require("@/assets/images/app/video_call.png");
      } else if (+type === 1) {
        return require("@/assets/images/app/audio_call.png");
      } else if (+type === 3) {
        return require("@/assets/images/app/silent_call.png");
      } else if (+type === 2) {
        return require("@/assets/images/app/edit_call.png");
      }
    },
    cancelClick(item) {
      this.$set(item, "isShow", false);
    },
    itemClick(item) {
      if (+item.type === 0 || +item.type === 1) {
        this.isShowMsg = false;
        //语音，视频报警
        if (this.$route.path === "/home") {
          this.$bus.$emit("callPerson", item.queue_id);
          return;
        }
        this.$router.push({
          path: "/home",
          query: {
            queue_id: item.queue_id,
          },
        });
      } else if (+item.type === 2) {
        this.modelList = [];
        //留言报警
        if (this.$route.path === "/messageAlarm") {
          this.$bus.$emit("selectMessageAlarm", item.log_id);
          return;
        }
        this.$router.push({
          path: "/messageAlarm",
          query: {
            log_id: item.log_id,
          },
        });
      } else if (+item.type === 3) {
        this.modelList = [];
        //静默求助
        if (this.$route.path === "/silentAlarm") {
          this.$bus.$emit("selectSilentAlarm", item.log_id);
          return;
        }
        this.$router.push({
          path: "/silentAlarm",
          query: {
            log_id: item.log_id,
          },
        });
      }
    },
    
  },
  created() {},
  mounted() {
    this.$bus.$on("getModelData", (data) => {
      const item = data;
      this.$set(item, "isShow", true);
      console.log(item);
      this.modelList.unshift(item);
    });
    this.$bus.$on("setShow", () => {
      this.modelList.pop();
      this.isShowMsg = true;
    });
    this.$bus.$on("clearModel", () => {
      this.modelList = [];
    });
    this.$bus.$on("openInfoList", (item) => {
      this.getInfo(item.title,item.icon,item.path)
    });
  },
  destroyed() {
    this.$bus.$off("getModelData");
    this.$bus.$off("setShow");
    this.$bus.$off("clearModel");
    this.$bus.$off("openInfoList");
  },
};
</script>

<style lang="scss" scoped>
.model_bg {
  .my_model {
    width: 40%;
    min-width: 500px;
    height: 600px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 20px 0px rgba(100, 100, 100, 0.1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 48px;
    display: flex;
    flex-direction: column;
    z-index: 999;
    .model_title {
      height: 57px;
      font-size: 22px;
      display: flex;
      justify-content: center;
      font-weight: 700;
      color: #313131;
    }
    .model_picture {
      height: 110px;
      display: flex;
      justify-content: center;
      img {
        width: 110px;
        height: 110px;
      }
    }
    .model_forms {
      margin-top: 48px;
      height: 210px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .forms {
        display: flex;
        .title {
          width: 25%;
          height: 36px;
          line-height: 36px;
          font-size: 18px;
          font-weight: 500;
          color: #707070;
        }
        .content {
          padding-left: 24px;
          width: 75%;
          line-height: 36px;
          height: 36px;
          background: #f9f9f9;
          border-radius: 5px;
          font-weight: 500;
          font-size: 14px;
          color: #707070;
        }
      }
    }
    .btn_group {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .btn {
        width: 108px;
        height: 52px;
        line-height: 52px;
        text-align: center;
        background: #f4725d;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
      }
      .cancel_btn {
        width: 108px;
        height: 52px;
        line-height: 52px;
        text-align: center;
        background: #bebebe;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
</style>