<template>
  <div class="table">
    <div class="btn">
      <div class="btn_group">
        <div class="green" @click="statusClick(2)">已接入派警系统</div>
        <div class="blue" @click="statusClick(1)" style="display:none;">标记为派警中</div>
        <div class="yellow" @click="statusClick(0)">标记为未派警</div>
        <div class="red" @click="statusClick(-1)">标记为无效报警</div>
      </div>
      <!-- <div class="btns">批量处理</div> -->
    </div>
    <div class="table_div">
      <el-table
        ref="multipleTable"
        :data="alarmList"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        border
        stripe
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column label="序号" width="65" align="center">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="报警类型" width="120" align="center">
          <template slot-scope="scope">{{
            scope.row.type | alarmType
          }}</template>
        </el-table-column>
        <el-table-column label="报警时间" width="200" align="center">
          <template slot-scope="scope">{{
            scope.row.createtime | getTime
          }}</template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="报警人昵称"
          width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="联系电话"
          width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="报警位置"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope"
            ><div @click="addressClick(scope.row)" class="address_div">
              {{ scope.row.address }}
            </div></template
          >
        </el-table-column>
        <el-table-column
          prop="ip"
          label="IP地址"
          width="160"
          align="center"
        >
        </el-table-column>
        <el-table-column label="求助备注" width="120" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="recordClick(scope.row)">
              点击查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="求助备注" :visible.sync="isShowUpdateAlarm" width="30%">
      <div class="forms">
        <el-input
          v-model="updateAlarmForm.content"
          placeholder="请输入内容"
          type="textarea"
          resize="none"
        ></el-input>
      </div>
      <div class="footer">
        <div class="confrim" @click="editAlarmItem">确认</div>
        <div class="cancel" @click="isShowUpdateAlarm = false">取消</div>
      </div>
    </el-dialog>
    <el-dialog
      title="位置"
      :visible.sync="isShowMap"
      width="50%"
      @close="closeCallBack"
      center
    >
      <my-map :mapFormData="mapFormData" ref="mapRef" />
    </el-dialog>
  </div>
</template>

<script>
import { timeSf } from "@/common/date.js";
import { setSmartrplog } from "@/api/alarmRecord/alarmRecord.js";
import myMap from "@/components/myMap/myMap.vue";
export default {
  components: { myMap },
  props: {
    alarmList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      multipleSelection: [],
      ids: "", //多选选择的项
      isShowUpdateAlarm: false,
      isShowMap: false,
      //弹出框数据
      updateAlarmForm: {
        content: "",
        id: "", //需要编辑的id
      },
      //地图弹出框数据
      mapFormData: {
        avator: "",
        name: "",
        mobile: "",
        address: "",
        lat: 0,
        lng: 0,
      },
      map: "",
    };
  },
  filters: {
    alarmType(type) {
      if (type == 0) {
        return "视频报警";
      } else if (type == 1) {
        return "音频报警";
      } else if (type == 2) {
        return "留言报警";
      } else if (type == 3) {
        return "静默求助";
      }
    },
    getTime(time) {
      return timeSf(time * 1000);
    },
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    statusClick(status) {
      if (!this.multipleSelection.length) {
        return this.$message.error("请先选择需要变更的数据");
      }
      this.ids = this.multipleSelection.map((item) => item.id).join(",");
      this.$emit("setAllPlog", this.ids, "status", status);
    },
    //点击编辑警情记录
    recordClick(item) {
      this.updateAlarmForm.id = item.id;
      this.updateAlarmForm.content = item.content;
      this.isShowUpdateAlarm = true;
    },
    //编辑弹框点击确认按钮
    editAlarmItem() {
      console.log(this.updateAlarmForm);
      this.$emit("editAlarmItem", this.updateAlarmForm);
      this.isShowUpdateAlarm = false;
    },
    //点击位置
    addressClick(item) {
      this.mapFormData = {
        avator: item.user?item.user.avatar:'',
        id: item.id,
        name: item.name,
        mobile: item.mobile,
        address: item.address,
        lat: item.lat,
        lng: item.lng,
      };
      console.log(this.mapFormData);
      this.isShowMap = true;
      this.$nextTick(()=>{
        this.$refs.mapRef.initMap(this.mapFormData.lat, this.mapFormData.lng);
      })
      //
    },
    closeCallBack() {
      this.$refs.mapRef.deleteMap();
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  margin-top: 15px;
  width: 100%;
  min-height: 65vh;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 30px 0px rgba(244, 114, 93, 0.1);
  .address_div {
    height: 100%;
    cursor: pointer;
    color: #409eff;
  }
  .btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 15px;
    .btns {
      margin-left: 15px;
      width: 104px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #f4725d;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .btn_group {
    height: 84px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    .blue {
      margin-left: 10px;
      width: 132px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #6fafff;
      border-radius: 5px;
      cursor: pointer;
    }
    .yellow {
      margin-left: 10px;
      width: 132px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #ffa767;
      border-radius: 5px;
      cursor: pointer;
    }
    .green {
      margin-left: 10px;
      width: 146px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #24d3b0;
      border-radius: 5px;
      cursor: pointer;
    }
    .red {
      margin-left: 10px;
      width: 146px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #e75740;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .table_div {
    padding: 15px;
    min-height: 450px;
  }
  .forms {
    height: 280px;
    .forms_item {
      display: flex;
      height: 70px;
      padding: 0 10%;
      .title_div {
        width: 25%;
        display: flex;
        justify-content: left;
        align-items: center;
      }
      .input_div {
        width: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .forms_area {
      display: flex;
      height: 150px;
      padding: 0 10%;
      .title_div {
        width: 25%;
        display: flex;
        justify-content: left;
        margin-top: 30px;
      }
      .input_div {
        width: 75%;
        display: flex;
        justify-content: center;
      }
    }
  }
  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100px;
    justify-content: space-evenly;
    .confrim {
      width: 108px;
      height: 52px;
      text-align: center;
      line-height: 52px;
      background: #f4725d;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
    .cancel {
      width: 108px;
      height: 52px;
      background: #bebebe;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      line-height: 52px;
      cursor: pointer;
    }
  }
}
</style>