<template>
  <div class="connect_queue">
    <div class="connect_title">
      <img src="@/assets/images/home/connect_queue/connect_queue.png" alt="" />
      <div class="title">接线等待队列({{ num }}人)</div>
    </div>
    <div class="queue">
      <el-row>
        
          <queue-item v-for="item in queue" :key="item.id" @click.native="itemClick(item)" :item="item"/>
        
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  getQueue, //获取队列
  getAqueue, //队列接听
  stopQueue, //结束通话
} from "@/api/home/home";
import QueueItem from "./queueItem.vue";
export default {
  components: {
    QueueItem,
  },
  data() {
    return {
      num: 0,
      queue: [],
      timer: "",
    };
  },
  methods: {
    getQueue() {
      getQueue().then((res) => {
        this.num = res.data.count;
        this.queue = res.data.list;
      });
    },
    itemClick(item) {
      sessionStorage.setItem("queueItem", JSON.stringify(item));
      getAqueue({
        log_id: item.log_id,
      }).then((res) => {
        if (res.code != 1) {
          //请先关闭上一次通话
          if (res.code == 1100) {
            this.$confirm("请先关闭上一次通话", "提示", {
              confirmButtonText: "确定",
              callback: (action) => {
                stopQueue({ log_id: res.data.log_id }).then((res) => {});
              },
            });
          }
          return this.$message.error(res.msg);
        }
        console.log("我点击了队列");
        console.log(item);
      });
    },
    selectQueue(queue) {
      getQueue({
        id: queue,
        status: 0,
      }).then((res) => {
        if (!res.data.list.length) {
          this.$bus.$emit("setShow");
          return this.$message.error("对话已被接听或已被取消!");
        }
        this.$bus.$emit("clearModel");
        sessionStorage.setItem("queueItem", JSON.stringify(res.data.list[0]));
        console.log(res.data.list[0]);
        getAqueue({
          log_id: res.data.list[0].log_id,
        }).then((data) => {
          if (data.code != 1) {
            //请先关闭上一次通话
            if (data.code == 1100) {
              this.$confirm("请先关闭上一次通话", "提示", {
                confirmButtonText: "确定",
                callback: (action) => {
                  stopQueue({ log_id: data.data.log_id }).then(() => {
                    return;
                  });
                },
              });
            }
          }
        });
      });
    },
  },
  created() {
    this.getQueue();
    this.timer = setInterval(() => {
      if (this.$route.path == "/home") {
        this.getQueue();
      } else {
        clearInterval(this.timer);
      }
    }, 1000);
    if (this.$route.query.queue_id) {
      this.selectQueue(this.$route.query.queue_id);
    }
  },
  mounted() {
    this.$bus.$on("callPerson", (queue_id) => {
      console.log(queue_id, 111);
      this.selectQueue(queue_id);
    });
  },
  destroyed() {
    this.$bus.$off("callPerson");
  },
};
</script>

<style lang="scss" scoped>
.connect_queue {
  margin-top: 2vh;
  height: 54vh;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 30px 0px rgba(244, 114, 93, 0.1);
  display: flex;
  flex-direction: column;
  .connect_title {
    height: 57px;
    display: flex;
    align-items: center;
    margin-left: 24px;
    img {
      width: 22px;
      height: 8px;
    }
    .title {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #313131;
    }
  }
  .queue {
    overflow: auto;
    flex: 1;
    .el-col{
      display: flex;
      justify-content: center;
      align-items: center;
      
    }
    .queue_item {
      width: 130px;
      height: 150px;
      .queue_img {
        width: 130px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f9f9f9;

        img {
          width: 60px;
          height: 60px;
        }
      }
      .queue_time {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        color: #707070;
      }
    }
  }
}
</style>