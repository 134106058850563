<template>
  <div class="func_area">
    <div class="left">
      <div
        class="event_item"
        v-for="(item, index) in eventThink"
        :key="index"
        :class="{ active: index == curr }"
        @click="itemClick(index, item.status)"
      >
        <div class="num" :class="{ active_item: index == curr }">
          {{ item.num }}
        </div>
        <div class="title" :class="{ active_item: index == curr }">
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="main">
      <el-row :gutter="20">
        <el-col :xs="6" :sm="6" :md="4" :lg="2" :xl="2">
          <div class="forms" >
            <div class="title" style="padding-left:20px" >时间范围</div>
          </div>
        </el-col>
        <el-col :xs="18" :sm="18" :md="8" :lg="10" :xl="10">
          <div class="forms" >
            <div class="form_div" >
              <el-date-picker
                v-model="dateTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right"
                @change="pickerChange"
              >
              </el-date-picker>
            </div>
          </div>
        </el-col>
        <el-col :xs="6" :sm="6" :md="4" :lg="2" :xl="2">
          <div class="forms" >
            <div class="title" >接警坐席</div>
          </div>
        </el-col>
        <el-col :xs="18" :sm="18" :md="8" :lg="10" :xl="10">
          <div class="forms" >
            <div class="form_div" >
              <div class="info" >
                <el-input
                  v-model="sitDown"
                  placeholder="请输入内容"
                  disabled
                ></el-input>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :xs="6" :sm="6" :md="4" :lg="2" :xl="2">
          <div class="forms" >
            <div class="title" style="padding-left:20px" >报警人称谓</div>
          </div>
        </el-col>
        <el-col :xs="18" :sm="18" :md="8" :lg="4" :xl="4">
          <div class="forms" >
            <div class="form_div" >
              <div class="info" >
                <el-input v-model="name" placeholder="请输入内容"></el-input>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :xs="6" :sm="6" :md="4" :lg="2" :xl="2">
          <div class="forms" >
            <div class="title" >回访电话</div>
          </div>
        </el-col>
        <el-col :xs="18" :sm="18" :md="8" :lg="4" :xl="4">
          <div class="forms" >
            <div class="form_div" >
              <div class="info" >
                <el-input v-model="phone" placeholder="请输入内容"></el-input>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :xs="6" :sm="6" :md="4" :lg="2" :xl="2">
          <div class="search" >
            <div class="search_title" >记录关键词</div>
          </div>
        </el-col>
        <el-col :xs="18" :sm="18" :md="20" :lg="10" :xl="10">
          <div class="search" >
            <div class="search_forms" >
              <el-input
                v-model="searchContent"
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <!-- <el-col :xs="6" :sm="6" :md="4" :lg="2" :xl="2">
          <div class="dropdown">
            <div class="title">警情类型</div>
          </div>
        </el-col>
        <el-col :xs="18" :sm="18" :md="4" :lg="4" :xl="4">
          <div class="dropdown">
            <div class="form_div">
              <el-select v-model="caseType" placeholder="请选择">
                <el-option
                  v-for="item in caseTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </el-col>
        <el-col :xs="6" :sm="6" :md="4" :lg="2" :xl="2">
          <div class="dropdown">
            <div class="title">场所类型</div>
          </div>
        </el-col>
        <el-col :xs="18" :sm="18" :md="4" :lg="4" :xl="4">
          <div class="dropdown">
            <div class="form_div">
              <el-select v-model="placeType" placeholder="请选择">
                <el-option
                  v-for="item in placeTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </el-col>
        <el-col :xs="6" :sm="6" :md="4" :lg="2" :xl="2">
          <div class="dropdown">
            <div class="title">事发路段</div>
          </div>
        </el-col>
        <el-col :xs="18" :sm="18" :md="4" :lg="4" :xl="4">
          <div class="dropdown">
            <div class="form_div">
              <el-select v-model="roadType" placeholder="请选择">
                <el-option
                  v-for="item in roadTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </el-col> -->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="btn_group">
            <div class="confrim" @click="searchClick">搜索</div>
            <div class="cancel" @click="clearForm">清空条件</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getObtain } from "@/api/alarmRecord/alarmRecord";
export default {
  props: {
    caseTypeList: {
      type: Array,
      default() {
        return [];
      },
    },
    placeTypeList: {
      type: Array,
      default() {
        return [];
      },
    },
    roadTypeList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      eventThink: [
        {
          title: "未处理",
          num: 0,
          status: 0,
        },
        {
          title: "已转入系统",
          num: 0,
          status: 2,
        },
        {
          title: "无效报警",
          num: 0,
          status: -1,
        },
        {
          title: "其他",
          num: 0,
          status: 1,
        },
      ],
      curr: 0, //当前活跃index
      status: 0, //处理状态
      dateTime: "", //选择的时间
      time: "", //处理后的时间
      sitDown: this.$store.state.account.name, //坐席
      name: "", //报警员称谓
      phone: "", //电话号码
      searchContent: "", //搜索内容
      caseType: "", //警情类型
      placeType: "", //场所类型
      roadType: "", //路段注明
      options: [],
      value: "",
    };
  },
  methods: {
    //点击左侧分类
    itemClick(index, status) {
      this.curr = index;
      this.status = this.eventThink[index].status;
      this.clearForm();
      this.$emit("statusChange", status);
    },
    //选择了日期
    pickerChange() {
      let timer = [];
      if (this.dateTime != null) {
        Object.values(this.dateTime).forEach((item) => {
          timer.push(Date.parse(item) / 1000);
        });
        this.time = `
      {
        "0":"between",
        "1":{
            "0":"${timer[0]}",
            "1":"${timer[1]}"
          }
      }`;
        this.time = JSON.parse(this.time);
        return;
      }
      this.time = "";
    },
    //清空表单
    clearForm() {
      this.dateTime = ""; //选择的时间
      this.name = ""; //报警人称谓
      this.phone = ""; //电话号码
      this.searchContent = ""; //搜索内容
      this.caseType = ""; //警情类型
      this.placeType = ""; //场所类型
      this.roadType = ""; //路段注明
      this.time = ""; //处理后的时间
    },
    //搜索
    searchClick() {
      this.$emit("searchAll", {
        status:this.status,//状态
        search: this.searchContent, //搜索内容
        casetype_id: this.caseType, //警情类型
        placetype_id: this.placeType, //场所类型
        road_id: this.roadType, //路段注明
        name: this.name, //报警员称谓
        mobile: this.phone, //电话号码
        time: this.time,
      });
    },
    //获取数量
    getObtains(obj) {
      getObtain(obj).then((res) => {
        this.$set(this.eventThink[0],'num',0)
        this.$set(this.eventThink[1],'num',0)
        this.$set(this.eventThink[2],'num',0)
        this.$set(this.eventThink[3],'num',0)
        res.data.forEach((item) => {
          if(+item.status===0){
            this.$set(this.eventThink[0],'num',this.eventThink[0].num+item.count)
          }else if(+item.status===1){
            this.$set(this.eventThink[1],'num',this.eventThink[1].num+item.count)
          }else if(+item.status===2){
            this.$set(this.eventThink[2],'num',this.eventThink[2].num+item.count)
          }else if(+item.status===-1){
            this.$set(this.eventThink[3],'num',this.eventThink[3].num+item.count)
          }
        });
      });
    }
  },
  created(){
    this.getObtains({
      field:'status',
      type:{
        0:'in',
        1:'2'
      }
    })
  }
};
</script>

<style lang="scss" scoped>
.el-col {
  display: flex;
  justify-content: center;
}
.func_area {
  width: 100%;
  min-height: 225px;
  border-radius: 5px;
  display: flex;
  .left {
    width: 120px;

    min-height: 225px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    .event_item {
      width: 105px;
      height: 50px;
      background: #ffffff;
      border-radius: 5px 0px 0px 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .title {
        font-size: 12px;
        font-weight: 500;
        color: #313131;
      }
      .num {
        font-size: 12px;
        font-weight: 500;
        color: #707070;
      }
    }
  }
  .main {
    flex: 1;
    min-height: 225px;
    background: #ffffff;
    box-shadow: 0px 4px 30px 0px rgba(244, 114, 93, 0.1);
    display: flex;
    flex-direction: column;
    .el-row {
      flex: 1;
      padding-right: 15px;
    }
    .forms {
      margin-top: 15px;
      width: 100%;
      display: flex;
      height: 36px;
      line-height: 36px;
      .title {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: #313131;
        text-align: left;
        padding-left: 5px;
      }
      .form_div {
        width: 100%;
        display: flex;
        align-items: center;
        .info {
          width: 100%;
          height: 100%;
          background: #f9f9f9;
          border-radius: 5px;
        }
      }
    }
    .search {
      margin-top: 15px;
      font-size: 14px;
      font-weight: 400;
      color: #313131;
      width: 100%;
      display: flex;
      .search_title {
        line-height: 36px;
        width: 100%;
        text-align: left;
        padding-left: 5px;
      }
      .search_forms {
        width: 100%;
      }
    }
    .dropdown {
      margin-top: 15px;
      width: 100%;
      display: flex;
      height: 36px;
      line-height: 36px;
      .title {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: #313131;
        text-align: left;
        padding-left: 5px;
      }
      .form_div {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 15px 0 0;
        .info {
          width: 100%;
          height: 100%;
          background: #f9f9f9;
          border-radius: 5px;
        }
      }
    }
    .btn_group {
      margin-top: 15px;
      height: 40px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      .confrim {
        width: 160px;
        height: 36px;
        background: #f4725d;
        border-radius: 5px;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
        margin-right: 15px;
      }
      .cancel {
        width: 160px;
        height: 36px;
        background: #bebebe;
        border-radius: 5px;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
        margin-right: 15px;
      }
    }
  }
}
.active {
  width: 115px !important;
  background: #f4725d !important;
  color: #ffffff !important;
}
.active_item {
  background: #f4725d !important;
  color: #ffffff !important;
}
</style>